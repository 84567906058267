import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import App from './App';
import * as serviceWorker from './serviceWorker';

//import './App.css';
import './assets/scss/style.scss';

// One Signal
const oneSignalHeader = document.createElement("script");
oneSignalHeader.async = false;
oneSignalHeader.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";

const oneSignalHeaderCode = document.createElement("script");
oneSignalHeaderCode.async = false;
oneSignalHeaderCode.innerText = `window.OneSignal = window.OneSignal || []; OneSignal.push(function() {OneSignal.init({appId: "1c3bd1cd-c7f5-4177-af9d-5e513a9d0d2a", safari_web_id: "web.onesignal.auto.122898af-6461-4378-9cd0-e897364a2895", notifyButton: {enable: true},});});`;
oneSignalHeaderCode.onload = () => this.scriptLoaded();

// Google Analytics
const analyticsHeaderStart = document.createComment(" Global site tag (gtag.js) - Google Analytics ");
const analyticsHeaderEnd = document.createComment(" End Google Analytics ");
const analyticsHeader = document.createElement("script");
analyticsHeader.async = true;
analyticsHeader.src = 'https://www.googletagmanager.com/gtag/js?id=G-FV42N8RDNS';
analyticsHeader.onload = () => this.scriptLoaded();
const analyticsHeaderCode = document.createElement("script");
analyticsHeaderCode.async = false;
analyticsHeaderCode.innerText = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-FV42N8RDNS');`;
analyticsHeaderCode.onload = () => this.scriptLoaded();

// Tag Manager
const tagManagerHeaderStart = document.createComment(" Google Tag Manager ");
const tagManagerHeaderEnd = document.createComment(" End Google Tag Manager ");
const tagManagerHeader = document.createElement("script");
tagManagerHeader.async = false;
tagManagerHeader.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KXSXGM6');`;
tagManagerHeader.onload = () => this.scriptLoaded();

const tagManagerBodyStart = document.createComment(" Google Tag Manager (noscript) ");
const tagManagerBodyEnd = document.createComment(" End Google Tag Manager (noscript) ");
const tagManagerBody = document.createElement("noscript");
const tagManagerBodyIframe = document.createElement("iframe");
tagManagerBodyIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-KXSXGM6';
tagManagerBodyIframe.height = '0';
tagManagerBodyIframe.width = '0';
tagManagerBodyIframe.style = 'display:none;visibility:hidden';
tagManagerBody.async = false;
tagManagerBody.appendChild(tagManagerBodyIframe);
tagManagerBody.onload = () => this.scriptLoaded();

// Yandex
const yandexHeader = document.createElement("noscript");
yandexHeader.async = false;
const yandexHeaderImg = document.createElement("img");
yandexHeaderImg.src = 'https://mc.yandex.ru/watch/71438458';
yandexHeaderImg.style = 'position:absolute;left:-9999px';
const yandexHeaderDiv = document.createElement("div");
yandexHeaderDiv.appendChild(yandexHeaderImg);
yandexHeader.appendChild(yandexHeaderDiv);
yandexHeader.onload = () => this.scriptLoaded();

const yandexHeaderCode = document.createElement("script");
yandexHeaderCode.async = false;
yandexHeaderCode.innerText = `!function(e, t, a, n, c, m, r) { e.ym = e.ym || function() { (e.ym.a = e.ym.a || []).push(arguments) }, e.ym.l = 1 * new Date, m = t.createElement(a), r = t.getElementsByTagName(a)[0], m.async = 1, m.src = "https://mc.yandex.ru/metrika/tag.js", r.parentNode.insertBefore(m, r) }(window, document, "script"), ym(71438458, "init", { clickmap: !0, trackLinks: !0, accurateTrackBounce: !0, webvisor: !0 })`;
yandexHeaderCode.onload = () => this.scriptLoaded();

//For head
document.head.appendChild(analyticsHeaderStart);
document.head.appendChild(analyticsHeader);
document.head.appendChild(analyticsHeaderCode);
document.head.appendChild(analyticsHeaderEnd);
document.head.appendChild(oneSignalHeader);
document.head.appendChild(oneSignalHeaderCode);
document.head.appendChild(tagManagerHeaderStart);
document.head.appendChild(tagManagerHeader);
document.head.appendChild(tagManagerHeaderEnd);
document.head.appendChild(yandexHeaderCode);
document.head.appendChild(yandexHeader);

// For body
document.body.appendChild(tagManagerBodyStart);
document.body.appendChild(tagManagerBody);
document.body.appendChild(tagManagerBodyEnd);

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
